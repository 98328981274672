"use strict";
var cAgencyDetailsEditor;

cAgencyDetailsEditor = function (el, type, data) {
    this.container = $(el);
    this.init();

    this.type = type;
    this.data = data ?? [];
    console.log('dt', this.data)
    if (this.data.length > 0) {
        for (let i = 0; i < this.data.length; i++) {
            this.addEditor(this.data[i]);
        }
    } else {
        this.drawEmptyList();
    }
    this.addListeners();
};

cAgencyDetailsEditor.prototype.addListeners = function () {
    $(".adder").on(
        "click",
        function () {
            this.addEditor();
        }.bind(this)
    );

    $(document).on(
        "click",
        ".remover",
        function (e) {
            console.log(
                "remover clicked",
                $(e.target).closest(".agency-details-element")
            );
            this.removeEditor(
                $(e.target).closest(".agency-details-editor-element")
            );
        }.bind(this)
    );
};

cAgencyDetailsEditor.prototype.init = function () {
    this.template = this.container
        .children(".agency-details-editor-template")
        .removeClass("agency-details-editor-template")
        .addClass("agency-details-editor-element")
        .detach();
};

cAgencyDetailsEditor.prototype.addEditor = function (values) {
    const editor = this.prepareEditor(
        this.template.clone(),
        this.container.children().length
    );
    this.container.append(editor);
    if (values) {
        this.populateValues(editor, values);
    }
    // this.finalizeEditor(editor);

    setTimeout(function (editor) {}.bind(this), 1000);

    editor.show();
};

cAgencyDetailsEditor.prototype.drawEmptyList = function () {
    this.container.append(
        '<div class="alert alert-warning">Non hai elementi in questa lista, completa il tuo profilo aggiungendone le informazioni che descrivono al meglio l\'azienda.</div>'
    );
};

cAgencyDetailsEditor.prototype.populateValues = function (editor, values) {
    const id = editor.attr("data-identifier");
    for (let key in values) {
        const el = editor.find(
            '[name="' + this.type + "[" + id + "][" + key + ']"]'
        );
        if (el) {
            el.val(values[key]);
        }
    }

    if (values.logo) {
        var img = '<img src="' + values.logo + '">';
        editor.find(".preview").append(img);
    }
};

cAgencyDetailsEditor.prototype.removeEditor = function (e) {
    const id = parseInt(e.attr("data-identifier"));
    let nid = id + 1;

    while ($('[data-identifier="' + nid + '"]').length > 0) {
        this.prepareEditor($('[data-identifier="' + nid + '"]'), nid - 1, nid);
        nid++;
    }

    e.remove();
};

cAgencyDetailsEditor.prototype.prepareEditor = function (
    editor,
    id,
    originalid
) {
    editor.attr("data-identifier", id);
    if (!originalid) {
        originalid = "identifier";
    }

    editor.find("input, textarea, select, checkbox, radio").each(function () {
        const on = $(this).attr("name");

        if (on) {
            const n = on.replace(originalid, id);
            $(this).attr("name", n);
            $(this).attr("id", n);
        }
    });

    return editor;
};

// cAgencyDetailsEditor.prototype.finalizeEditor = function (editor) {
//     const id = parseInt(editor.attr('data-identifier'));
//     const dzel = editor.find('#dz_identifier');
//     dzel.attr('id', 'dz_'+id);

//     const dzconfig = {
//         url:'/',
//         autoProcessQueue: false,
//         acceptedFiles: 'image/*',
//         maxFilesize: 0.5,
//         init: function () {
//             this.on("addedfile", function (file) {
//                 console.log();
//                 const pv = $(this.element).find('.dz-preview');
//                 if (pv.length > 1) {
//                     for (let i = 0; i < pv.length - 1; i++) {
//                         $(pv[i]).remove();
//                     }
//                 }
//             });
//             this.on("thumbnail", function (file) {
//                 this.qf_editor.find('[data-rel="base64"]').val(file.dataURL);
//             });
//         }
//     };

//     const logodz = new Dropzone('#'+dzel.attr('id'), dzconfig);
//     logodz.qf_editor = editor;
// }
window.cAgencyDetailsEditor = cAgencyDetailsEditor;
