'use strict';
var cVideoRecorder;
cVideoRecorder = function (el, autostart = true) {
    this.container = el;

    this.mediaRecorder = null;
    this.recordedBlobs = null;

    this.codecPreferences = this.container.find('.codecPreferences');
    this.errorMsgElement = this.container.find('.errorMsg');
    this.recordedVideo = this.container.find('video.recorded');
    this.recordButton = this.container.find('a.record');
    this.playButton = this.container.find('a.play');
    this.downloadButton = this.container.find('a.download');
    this.startButton = this.container.find('a.start');

    this.addListeners();

    if (autostart) {
        this.start();
    }
}

cVideoRecorder.prototype.addListeners = function() {
    this.recordButton.on('click', (() => {
        if (this.recordButton.text() === 'Start Recording') {
            this.startRecording();
        } else {
            this.stopRecording();
            this.recordButton.text('Start Recording');
            this.playButton.removeClass('disabled');
            this.downloadButton.removeClass('disabled');
            this.codecPreferences.removeClass('disabled');

            setTimeout((() => { this.playButton.trigger('click'); }).bind(this), 1000);
        }
    }).bind(this));

    this.playButton.on('click', (() => {
        const mimeType = this.codecPreferences.val().split(';', 1)[0];
        const superBuffer = new Blob(this.recordedBlobs, {type: mimeType});

        var reader = new window.FileReader();
        reader.readAsDataURL(superBuffer);
        reader.onloadend = (function() {
            const base64 = 'data:'+mimeType+';base64,'+reader.result.split(',')[1];
            this.container.find('[name="video"]').val(base64);
            this.container.find('[name="videosize"]').val(superBuffer.size)
        }).bind(this);

        this.recordedVideo[0].style.display = 'block';
        this.recordedVideo.prop('src', null);
        this.recordedVideo.prop('srcObject', null);
        this.recordedVideo.prop('src', window.URL.createObjectURL(superBuffer));
        this.recordedVideo.prop('controls', true);
        this.recordedVideo.trigger('play');
    }).bind(this));

    this.downloadButton.on('click', (() => {
        const blob = new Blob(recordedBlobs, {type: 'video/webm'});
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'test.webm';
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }, 100);
    }).bind(this));

    this.start = (async () => {
        this.startButton.addClass('disabled');
        const hasEchoCancellation = document.querySelector('.echoCancellation').checked;
        const constraints = { audio: true, video: { width: 1280, height: 720 } };
        /*
        constraint iniziali, eliminati per supportare Safari
        {
            audio: {
                echoCancellation: {exact: hasEchoCancellation}
            },
            video: { width: 1280, height: 720 }
        }
        */

        //console.log('Using media constraints:', constraints);
        await this.init(constraints);
    }).bind(this);

    this.init = (async (constraints) => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            this.handleSuccess(stream);
        } catch (e) {
        
            console.error('navigator.getUserMedia error:', e);
            this.errorMsgElement.innerHTML = `navigator.getUserMedia error:${e.toString()}`;
        }
    }).bind(this);
}

cVideoRecorder.prototype.handleDataAvailable = function(event) {
    if (event.data && event.data.size > 0) {
        this.recordedBlobs.push(event.data);
    }
}

cVideoRecorder.prototype.getSupportedMimeTypes = function() {
    const possibleTypes = [
        'video/webm;codecs=vp9,opus',
        'video/webm;codecs=vp8,opus',
        'video/webm;codecs=h264,opus',
        'video/mp4;codecs=h264,aac',
        'video/mp4;codecs=avc1.42E01E',
        'video/mp4; codecs="avc1.42E01E, mp4a.40.5"',   //H.264 level 3.0 	baseline 	AAC 	aac_he
        'video/mp4; codecs="avc1.42E01E, mp4a.40.2"',   //H.264 level 3.0 	baseline 	AAC 	aac_lc
        'video/mp4; codecs="avc1.42E01E, mp4a.69"',     //H.264 level 3.0 	baseline 	mp3
        'video/mp4; codecs="avc1.42E01E, mp4a.6B"',     //H.264 level 3.0 	baseline 	mp3
        'video/mp4; codecs="avc1.64002A, mp4a.40.5"',   //H.264 level 4.2 	high 	AAC
    ];
    return possibleTypes.filter(mimeType => {
        console.log('verifico se il mimeType ('+mimeType+') è supportato', MediaRecorder.isTypeSupported(mimeType));
        return MediaRecorder.isTypeSupported(mimeType);
    });
}

cVideoRecorder.prototype.startRecording = function() {
    this.recordedBlobs = [];
    const mimeType = this.codecPreferences.val();
    const options = {mimeType};
console.log('options startRecording',options);
    try {
        this.mediaRecorder = new MediaRecorder(window.stream, options);
    } catch (e) {
        console.error('Exception while creating MediaRecorder:', e);
        this.errorMsgElement.innerHTML = `Exception while creating MediaRecorder: ${JSON.stringify(e)}`;
        return;
    }

    console.log('Created MediaRecorder', this.mediaRecorder, 'with options', options);
    this.recordButton.text('Stop Recording');
    this.playButton.addClass('disabled');
    this.downloadButton.addClass('disabled');
    this.codecPreferences.addClass('disabled');

    this.mediaRecorder.onstop = ((event) => {
        console.log('Recorder stopped: ', event);
        console.log('Recorded Blobs: ', this.recordedBlobs);
    }).bind(this);

    this.mediaRecorder.ondataavailable = this.handleDataAvailable.bind(this);
    this.mediaRecorder.start();
    console.log('MediaRecorder started', this.mediaRecorder);
}

cVideoRecorder.prototype.stopRecording = function() {
    this.mediaRecorder.stop();
}

cVideoRecorder.prototype.stop = function() {
    if(typeof window.stream == typeof undefined) return;
    window.stream.getTracks().forEach(function(track) {
        track.stop();
    });
}

cVideoRecorder.prototype.handleSuccess = function(stream) {
    this.recordButton.removeClass('disabled');
    window.stream = stream;

    const gumVideo = this.container.find('video.gum');
    gumVideo.prop('srcObject', stream);

    this.getSupportedMimeTypes().forEach(mimeType => {
        const option = document.createElement('option');
        option.value = mimeType;
        option.innerText = option.value;
        this.codecPreferences.append(option);
    });
    this.codecPreferences.removeClass('disabled');
}

window.cVideoRecorder = cVideoRecorder;
