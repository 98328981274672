StepManager = function (el) {
    this.container = el;

    this.container.find('.button.next').on('click', (function(e) {
        if ($(e.target).is('input[type="submit"]')) {
            $(e.target).closest('form').submit();
        } else if (!$(e.target).attr('data-custom-action')) {
            const oldstep = $(e.target).closest('.step');
            const step = oldstep.next('.step');

            oldstep.removeClass('active');
            step.addClass('active');
            this.handleStepAppearance(step, oldstep);
        } else {
            window[$(e.target).attr('data-custom-action')]();
        }
    }).bind(this));

    this.container.find('.button.prev').on('click', (function(e) {
        if (!$(e.target).attr('data-custom-action')) {
            const oldstep = $(e.target).closest('.step');
            const step = oldstep.prev('.step');

            oldstep.removeClass('active');
            step.addClass('active');
            this.handleStepAppearance(step, oldstep);
        } else {
            $(e.target).attr('data-custom-action')();
        }
    }).bind(this));
}

StepManager.prototype.handleStepAppearance = function (step, oldstep) {
    if (typeof step.attr('data-appear-once') != typeof undefined) {
        eval(step.attr('data-appear-once'));
        step.removeAttr('data-appear-once');
    }

    if (typeof step.attr('data-appear') != typeof undefined) {
        eval(step.attr('data-appear'));
    }

    if (typeof oldstep.attr('data-disappear') != typeof undefined) {
        eval(oldstep.attr('data-disappear'));
    }

    $('html, body').animate({
        scrollTop: $('[name="steproof"]').offset().top - 100
    }, 500);
}
