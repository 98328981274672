Analytics = function () {
}

Analytics.prototype.parsepage = function () {
    const trackables = $('[data-trackable="true"]:not(.swiper-slide-duplicate)');
    let data = [];

    trackables.each(function() {
        let activity = {}
        activity.timestamp  = + new Date();
        activity.page_url   = location.protocol + '//' + location.host + location.pathname;
        activity.agency_id  = $(this).attr('data-id');
        activity.page_zone  = $(this).closest('[data-zone]').attr('data-zone');
        activity.event_type = 'view';

        data.push(activity);
    });
    
    $.ajax({
        type: 'POST',
        url: window.api+'/evt',
        data: JSON.stringify(data),
        success: function (j) {
            //console.log('SENT TRACKABLES', data);
            //console.log("ricevuto response", j);
        },
        error: function (j) {
            //console.log("ricevuto errore", j);
        }
    });
    
    //console.log('FOUND TRACKABLES', data);
}