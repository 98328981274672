'use strict';
var cTouchpoint;

cTouchpoint = function(el) {
    this.container = $(el);
    this.addListeners();
}

cTouchpoint.prototype.addListeners = function() {
    this.container.find('.form-intro-answer').on('click', (e) => {
        let el = $(e.currentTarget);

        if ($(el).closest('.component.leadform').hasClass('dynamic')) {
            if ($('.form-body-modal:visible').length) return;
            el = $('#'+el.children('[name="introanswer"]').attr('id')).first();
        }

        $(el).closest('form').removeClass('closed');
        $(el).siblings().removeClass('selected');
        $(el).addClass('selected');
    });

    this.container.find('.form-body-modal').on('click', (e) => {
        let el = $(e.target);

        if (el.hasClass('form-body-modal')) {
            $(el).closest('form').addClass('closed');
        }
    });
}

window.cTouchpoint = cTouchpoint;