'use strict';
var cToc;

cToc = function(el) {
    this.container = $(el);
    this.addListeners();
}

cToc.prototype.addListeners = function() {
    this.container.find('.toclink').on('click', (e) => {
        let el = $(e.currentTarget);

        e.preventDefault();

        $('html, body').animate({scrollTop: $(el.attr('href')).offset().top -100 }, 500);

    });

}

window.cToc = cToc;