'use strict';
var cBriefViewer;

cBriefViewer = function (el, type, data, goals, province, sender, me) {
    this.container = $(el);
    this.init();

    this.type = type;
    this.data = data;
    this.me = me;
    this.goals = {};
    for (let i in goals) {
        this.goals[goals[i].slug] = goals[i].name;
    }
    this.province = {};
    for (let i in province) {
        this.province[province[i].slug] = province[i].name;
    }
    this.sender = {};
    for (let i in sender) {
        this.sender[sender[i].slug] = sender[i].name;
    }

    if (this.data.length > 0) {
        for (let i = 0; i < this.data.length; i++) {
            this.addEditor(this.data[i]);
        }
    } else {
        this.drawEmptyList();
    }

    this.addListeners();
}

cBriefViewer.prototype.addListeners = function() {
    $('.brief-short').on('click', function() {
        $(this).siblings('.brief-full').toggleClass('open');
    });
}

cBriefViewer.prototype.init = function() {
    this.template = this.container.children('.brief-viewer-template').removeClass('brief-viewer-template').addClass('brief-viewer-element').detach();
}

cBriefViewer.prototype.addEditor = function(values) {
    const editor = this.prepareEditor(this.template.clone(), this.container.children().length);
    this.container.append(editor);
    if (values) {
        this.populateValues(editor, values);
    }

    editor.show();
}

cBriefViewer.prototype.drawEmptyList = function() {
    if (this.type == 'sender') {
        this.container.append('<div class="alert alert-success">Non hai creato nessun brief, che ne pensi di iniziare ora?</div>');
    } else {
        this.container.append('<div class="alert alert-info">Non hai ancora ricevuto brief da clienti e prospect.<br/>Completa al meglio il tuo profilo per attirare Buyer sulla tua pagina e ingaggiarli commercialmente.</div>');
    }
}

cBriefViewer.prototype.populateValues = function(editor, values) {
    const id = editor.attr('data-identifier');
    for (let key in values) {
        const el = editor.find('[data-rel="'+this.type+'['+id+']['+key+']"]');
        if (el) {
            switch (key) {
                case 'goal':
                    el.text(this.goals[values[key]]);
                    break;
                case 'province':
                    el.text(this.province[values[key]]);
                    break;
                case 'due_at':
                case 'created_at':
                    if (values[key] != null) {
                        const dt = values[key].substring(0, 10).split('-');
                        el.text(dt[2]+'/'+dt[1]+'/'+dt[0]);
                    }
                    break;
                case 'budget_type':
                    const budgetcell = editor.find('[data-rel="'+this.type+'['+id+'][budget]"]');
                    budgetcell.attr('data-type', values[key]);
                    if (values[key] == 'corpo' && values['budget']) {
                        budgetcell.html(values['budget'].replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'&euro;')
                    } else if (values[key] == 'tem' && values['ratecard']) {
                        budgetcell.html(values['ratecard'].replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'&euro;')
                    } else {
                        el.text('-');
                    }
                    break;
                case '_budget': //deprecated
                case '_ratecard'://deprecated
                    if (values[key]) {
                        el.html(values[key].replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'&euro;');
                    } else {
                        el.text('-');
                    }
                    break;
                case 'video':
                    if (values[key]) {
                        el.html('<video controls width="250"><source src="'+values[key]+'" type="video/webm">Sorry, your browser doesn\'t support embedded videos.</video>');
                    } else {
                        el.empty();
                    }
                    break;
                case 'media':
                    if (values[key] && values[key].length > 0 && values[key][0]['name'] != null) {
                        el.append('<a href="'+values[key][0]['file']+'" target="_blank">Scarica il file '+values[key][0]['name']+'</a>');
                    }
                    break;
                case 'agencies':
                    const ac = values[key].length;
                    editor.find('[data-rel="'+this.type+'['+id+'][is_assigned]"]').text(ac);
                    let lst = '';
                    if (ac > 0) {
                        lst = '<ul>'
                        for (let i = 0; i < ac; i++) {
                            lst += '<li><a href="/agency/'+values[key][i]['slug']+'" target="_blank" class="button action micro">'+values[key][i]['name']+'</a></li>';
                        }
                        lst += '</ul>'
                    }
                    el.append(lst);
                    break;
                case 'description':
                    if (values[key]) {
                        el.html(values[key]);
                    } else {
                        el.empty();
                    }
                    break;
                case 'sender':
                    let shtml = '';

                    if (values[key] && Object.keys(values[key]).length) {
                        shtml = '<ul>'
                        for (let i in values[key]) {
                            if (!values[key][i]) {
                                continue;
                            }
                            if (i == 'industry') {
                                shtml += '<li><span class="label">'+this.sender[i]+':</span>'+values[key][i]['name']+'</li>';
                            } else {
                                shtml += '<li><span class="label">'+this.sender[i]+':</span>'+values[key][i]+'</li>';
                            }
                        }
                        shtml += '</ul>'
                    }

                    el.append(shtml);

                    if (this.type == 'receiver') {
                        console.log('dati per disegnare il bottone di manifestaizone', this.me);
                        let ihtml = '<div class="interest">';
                        if (values.tenders.length == 0) {
                            ihtml += '<a class="action button primary" onclick="window.cbvbrief.showInterestForm(this)" data-briefId="'+values.id+'"  data-briefName="'+values.name+'" data-firstname="'+this.me.name+'" data-lastname="'+this.me.lastname+'" data-email="'+this.me.email+'" data-alias="'+values.sender.alias+'">Mi interessa</a>';
                        } else {
                            ihtml += 'Manifestazione di interesse registrata'
                        }
                        ihtml += '</div>';
                        el.after(ihtml);
                    }
                    break;
                default:
                    el.text(values[key]);
            }
        }
    }

    if (values.logo) {
        var img = '<img src="'+values.logo+'">';
        editor.find('.preview').append(img);
    }
}

cBriefViewer.prototype.removeEditor = function(e) {
    const id = parseInt(e.attr('data-identifier'));
    let nid = id + 1;

    while ($('[data-identifier="'+ nid +'"]').length > 0) {
        this.prepareEditor($('[data-identifier="'+ nid +'"]'), (nid - 1), nid);
        nid ++;
    }

    e.remove();
}


cBriefViewer.prototype.showInterestForm = function(e) {
    $(e).hide();
    
    const data = {
        "briefId":$(e).attr('data-briefId'),
        "briefName":$(e).attr('data-briefName'),
        "alias":$(e).attr('data-alias'),
        "firstname":$(e).attr('data-firstname'),
        "lastname":$(e).attr('data-lastname'),
        "email":$(e).attr('data-email'),
    }

    $.ajax({
        type: 'GET',
        url: '/auth/tender/interest',
        data: data,
        context: this,
        success: function (j) {
            $(e).parent().append(j);
        },
        error: function (j) {
            console.log("ricevuto errore", j);
        }
    });
}

cBriefViewer.prototype.submitInterestForm = function(e) {    
    $.ajax({
        type: 'POST',
        url: '/auth/tender/interest',
        data: $(e).serialize(),
        context: this,
        success: function (j) {
            $(e).parent().html('Manifestazione di interesse inviata');
        },
        error: function (j) {
            $(e).parent().html('Errore, contatta support@qualifier.it');
        }
    });

    return false;
}

cBriefViewer.prototype.prepareEditor = function (editor, id, originalid) {
    editor.attr('data-identifier', id);
    if (!originalid) {
        originalid = 'identifier';
    }

    editor.find('div[data-rel]').each(function() {
        const on =  $(this).attr('data-rel');

        if (on) {
            const n = on.replace(originalid, id);
            $(this).attr('data-rel', n);
        }
    });

    return editor;
}

window.cBriefViewer = cBriefViewer;
