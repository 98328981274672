'use strict';
var cBusinessIntelligenceGraph;

cBusinessIntelligenceGraph = function (el, data) {
    this.container = $(el);
    this.map = this.container.find('.asset.map svg');
    this.chartsearched = this.container.find('.cell.searched .graph');
    this.chartoffered = this.container.find('.cell.offered .graph');
    this.data = data;
    this.addListeners();
    this.drawmap();
    this.drawcharts();
}

cBusinessIntelligenceGraph.prototype.addListeners = function() {
    this.map.find('#level1 g').on('click', (function(e) {
        const el = $(e.currentTarget);
        const rel = el.attr('id').split('_1_')[0];
        el.hide();
        this.map.find('#level2').children('#'+rel+'_2_').show();
        //console.log(this.map.find('#level2'));
        //console.log(this.map.find('#level2').children('#'+rel+'_2_'));
    }).bind(this));

    this.map.find('#level2 > g > g').on('click', (function(e) {
        const el = $(e.currentTarget);
        const rel = el.parent().attr('id').split('_2_')[0];
        el.parent().hide();

        this.map.find('#'+rel+'_1_').show();
    }).bind(this));
}

cBusinessIntelligenceGraph.prototype.drawmap = function() {
    this.map.find('#level1').hide();
    this.map.find('#level2').show();
    this.map.find('#level2').children().hide();
    this.map.find('#level' + this.data.map.level).show();

    for (let id in this.data.map.values.macroregions) {
        this.map.find('#'+id+'_1_ text').attr('text-anchor', "middle").text(this.data.map.values.macroregions[id]);
    }

    for (let id in this.data.map.values.regions) {
        this.map.find('#'+id+'_1_ text').attr('text-anchor', "middle").text(this.data.map.values.regions[id]);
    }
}


cBusinessIntelligenceGraph.prototype.drawcharts = function() {
    this.chartsearched.find('.valuewrapper').each(function() {
        const w =  ($(this).children('.barbottom').innerWidth() - 20) * Math.pow(parseInt($(this).attr('data-value')) / parseInt($(this).attr('data-max')), 0.35);

        $(this).children('.bar').css('width', w);
        $(this).children('.value').css('left', w);
    });

    this.chartoffered.find('.valuewrapper').each(function() {
        //const w = parseInt($(this).attr('data-value')) * 100 / parseInt($(this).attr('data-max'));
        const w =  ($(this).children('.barbottom').innerWidth() - 20) * Math.pow(parseInt($(this).attr('data-value')) / parseInt($(this).attr('data-max')), 0.35);

        $(this).children('.bar').css('width', w);
        $(this).children('.value').css('left', w);
    });
}

window.cBusinessIntelligenceGraph = cBusinessIntelligenceGraph;
