cSearchBar = function(el) {
    this.container = el;
    this.searchbox = el.find('.searchBox');
    this.resultwrapper = el.find('.searchResultWrapper');
    this.searchtext = '';
    this.addListeners();
}

cSearchBar.prototype.addListeners = function() {
    this.timeout = null;

    this.searchbox.on('input', (function () {
        const v = this.searchbox.val();
        const clean_v = v.replace(/[^A-Za-z0-9àèéìòù\- ]/g, '').replace(/ +/g, ' ');
        if (clean_v != v) {
            this.searchbox.val(clean_v);
            return;
        }
        this.searchtext = v;
        clearTimeout(this.timeout);

        this.timeout = setTimeout((function () {
            this.searchbox.parent().addClass('searching');
            this.resultwrapper.html('');
            $.ajax({
                type: 'GET',
                url: '/search',
                data: 'type=ajax&q=' + this.searchtext,
                context: this,
                success: this.handleResults
            });
        }).bind(this), 400);

    }).bind(this));

    $(document).on('mouseup', (function(e) {
        if (!this.container.is(e.target) && this.container.has(e.target).length === 0) {
            this.resultwrapper.empty();
        }
    }).bind(this));
}

cSearchBar.prototype.handleResults = function(content) {
    const pattern = '(?![^<]*>)' + this.searchtext;
    const re = new RegExp(pattern, 'gmi');
    const res = content.replace(re, (match) => `<mark>${match}</mark>`);

    this.searchbox.parent().removeClass('searching');
    this.resultwrapper.html(res);
}

cSearchBar.prototype.getFocus = function() {
    const st = this.container.offset().top - 200;

    $([document.documentElement, document.body]).animate({
        scrollTop: st
    }, 500);
    this.searchbox.focus();

    return void(0);
}
