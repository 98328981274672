
'use strict';
var cAgencyProductsEditor;

cAgencyProductsEditor = function (el, type, data) {
    console.log('el ', el);
    this.container = $(el);
    this.init();

    this.type = type;
    this.data = data;
    if (this.data.length > 0) {
        for (let i = 0; i < this.data.length; i++) {
            this.addEditor(this.data[i]);
        }
    } else {
        this.drawEmptyList();
    }

    this.addListeners();
}

cAgencyProductsEditor.prototype.addListeners = function() {
    $('.adder').on('click', (function() {
        this.addEditor();
    }).bind(this));

    $(document).on('click', '.remover', (function(e) {
        console.log('remover clicked', $(e.target).closest('.agency-products-element'));
        this.removeEditor($(e.target).closest('.agency-products-editor-element'));
    }).bind(this));
}

cAgencyProductsEditor.prototype.init = function() {
    this.template = this.container.children('.agency-products-editor-template').removeClass('agency-products-editor-template').addClass('agency-products-editor-element').detach();
}

cAgencyProductsEditor.prototype.addEditor = function(values) {
    const editor = this.prepareEditor(this.template.clone(), this.container.children().length);
    this.container.append(editor);
    if (values) {
        this.populateValues(editor, values);
    }
    this.finalizeEditor(editor);

    setTimeout((function (editor) {

    }).bind(this), 1000);

    editor.show();
}

cAgencyProductsEditor.prototype.drawEmptyList = function() {
    this.container.append('<div class="alert alert-warning">Non hai elementi in questa lista, completa il tuo profilo aggiungendone le informazioni che descrivono al meglio l\'azienda.</div>');
}

cAgencyProductsEditor.prototype.populateValues = function(editor, values) {
    const id = editor.attr('data-identifier');
    for (let key in values) {
        const el = editor.find('[name="'+this.type+'['+id+']['+key+']"]');
        if (el) {
            el.val(values[key]);
        }
    }

    if (values.wallpaper) {
        var img = '<img src="'+values.wallpaper+'">';
        editor.find('.preview').append(img);
    }
}

cAgencyProductsEditor.prototype.removeEditor = function(e) {
    const id = parseInt(e.attr('data-identifier'));
    let nid = id + 1;

    while ($('[data-identifier="'+ nid +'"]').length > 0) {
        this.prepareEditor($('[data-identifier="'+ nid +'"]'), (nid - 1), nid);
        nid ++;
    }

    e.remove();
}

cAgencyProductsEditor.prototype.prepareEditor = function (editor, id, originalid) {
    editor.attr('data-identifier', id);
    if (!originalid) {
        originalid = 'identifier';
    }

    editor.find('input, textarea, select, checkbox, radio').each(function() {
        const on =  $(this).attr('name');

        if (on) {
            const n = on.replace(originalid, id);
            $(this).attr('name', n);
            $(this).attr('id', n);
        }
    });

    return editor;
}

cAgencyProductsEditor.prototype.finalizeEditor = function (editor) {
    const id = parseInt(editor.attr('data-identifier'));
    const dzel = editor.find('#dz_identifier');
    dzel.attr('id', 'dz_'+id);

    const dzconfig = {
        url:'/',
        autoProcessQueue: false,
        acceptedFiles: 'image/*',
        maxFilesize: 1,
        init: function () {
            this.on("addedfile", function (file) {
                const pv = $(this.element).find('.dz-preview');
                if (pv.length > 1) {
                    for (let i = 0; i < pv.length - 1; i++) {
                        $(pv[i]).remove();
                    }
                }
            });
            this.on("thumbnail", function (file) {
                this.qf_editor.find('[data-rel="base64"]').val(file.dataURL);
            });
        }
    };

    const logodz = new Dropzone('#'+dzel.attr('id'), dzconfig);
    logodz.qf_editor = editor;
}
window.cAgencyProductsEditor = cAgencyProductsEditor;
