require("./bootstrap");

import $ from "jquery";
window.$ = window.jQuery = $;

import Swiper from "swiper/bundle";
import CKeditor from "@ckeditor/ckeditor5-build-balloon";
import { Dropzone } from "dropzone";
import "particles.js";
import Chart from "chart.js/auto";
window.Chart = Chart;
import Typewriter from 'typewriter-effect/dist/core';


require("./_utils_custom_scrollbar");
require("./_step-manager");
require("./_analytics");
require("./_component-search-bar");
require("./_component-service-browser-percentage");
require("./_component-video-recorder");
require("./_component-business-intelligence-graph");
require("./_component-agency-details-editor");
require("./_component-agency-projects-editor");
require("./_component-agency-products-editor");
require("./_briefsmartstep-manager");
require("./_component-brief-viewer");
require("./_component-porfolio");
require("./_component-review");
require("./_component-touchpoint");
require("./_component-toc");

$(document).ready(function () {
    $(".scrollup").on("click", function () {
        $("html, body").animate(
            {
                scrollTop: 0,
            },
            500
        );
    });
    $(".hamburger").on("click", function () {
        const i = $(this).children(".icon");
        i.toggleClass("open");
        if (i.hasClass("open")) {
            $(this).parent().siblings(".ctawrapper:not(.auth)").show();
        } else {
            $(this).parent().siblings(".ctawrapper:not(.auth)").hide();
        }
    });

    $(document).on("click", ".contactagency", function () {
        $('html, body').animate({scrollTop: $('form.landingpage').offset().top - 100 }, 500);
    });

    if ($('#searchplaceholder').length > 0) {
        const strings = $('#searchplaceholder').attr('data-strings').split('|');
        if (strings.length > 0) {
            new Typewriter('#searchplaceholder', {
                strings: strings,
                autoStart: true,
                loop: true
            });
        }
    }

    $(".component.leadform").each((id, el) => {
        new cTouchpoint(el);
    });

    $(".component.toctable").each((id, el) => {
        new cToc(el);
    });

    const anc = new Analytics();
    anc.parsepage();
});
