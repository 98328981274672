var cServiceBrowserPercentage = function(el, sb) {
    $('li[data-role="service"]').not(':first-child').addClass('closed');
    $('li[data-role="service"] > div.label').on('click', function() {
        $(this).parent().toggleClass('closed');
    })

    this.element = el;
    this.originalcontent = el.html();
    this.searchbox = sb;
    this.searchtext = '';
    this.addListeners();
}

cServiceBrowserPercentage.prototype.addListeners = function() {
    this.toListener = null;

    this.searchbox.on('input', function () {
        window.csbp.searchtext = $(this).val();
        clearTimeout(window.csbp.toListener)
        window.csbp.toListener = setTimeout(function () {
            window.csbp.handleResults()
        }, 400);

    });
}

cServiceBrowserPercentage.prototype.handleResults = function() {
    if (this.searchtext.length < 2) return;

    let content = this.originalcontent.valueOf();

    const pattern = '(?![^<]*>)' + this.searchtext;
    const re = new RegExp(pattern, 'gmi');
    const res = content.replace(re, (match) => `<mark>${match}</mark>`);

    this.element.html(res);

    this.element.find('li[data-role="service"]').each(function() {
        if ($(this).find('mark').length > 0) {
            $(this).removeClass('closed');
        } else {
            $(this).addClass('closed');
        }
    })
}

$(document).ready(function() {
    window.csbp = new cServiceBrowserPercentage($('.component.service-percentage'), $('#search-service-percentage'));
});
