'use strict';
var cReview;

cReview = function(el) {
    this.container = el;
    this.addListeners();
}

cReview.prototype.addListeners = function() {
    this.container.find('div.button').on('click', $.proxy(function() {
        this.submitForm(this.container);
    }, this));
}


cReview.prototype.submitForm = function(e) {    
    $.ajax({
        type: 'POST',
        url: '/agency/review',
        data: $(e).serialize(),
        context: this,
        success: function (j) {
            $(e).parent().html('Recensione inviata, provvederemo ora a verificarne i contenuto prima di pubblicarla');
        },
        error: function (j) {
            $(e).parent().html('Errore, contatta support@qualifier.it');
        }
    });

    return false;
}

window.cReview = cReview;