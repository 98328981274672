'use strict';
var cPorfolio;

cPorfolio = function() {
    this.elements = $('.tile.project.zoomable');
    console.log('cPorfolio elements', this.elements);
    this.addListeners();
}

cPorfolio.prototype.addListeners = function() {
    this.elements.on('click', function () {
        window.cp.handleFullscreen(this);
    });

    $(document).on('click', '.component.project-modal', function() {
        $(this).remove();
        $('body').css('overflowY', 'scroll');
    })
}

cPorfolio.prototype.handleFullscreen = function(el) {
    let elclone = $(el).clone();
    $(elclone).find('.cover').prepend('<div class="image"></div>');
    $(elclone).find('img').appendTo($(elclone).find('.image'));
    const modal = '<div class="component project-modal">'+$(elclone).html()+'</div>';
    $('body').append(modal).css('overflowY', 'hidden');
}

window.cPorfolio = cPorfolio;