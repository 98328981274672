'use strict';
var cAgencyProjectsEditor;

cAgencyProjectsEditor = function (el, type, data) {
    this.container = $(el);
    this.init();

    this.type = type;
    this.data = data;
    if (this.data.length > 0) {
        for (let i = 0; i < this.data.length; i++) {
            this.addEditor(this.data[i]);
        }
    } else {
        this.drawEmptyList();
    }

    this.addListeners();
}

cAgencyProjectsEditor.prototype.addListeners = function () {
    $('.adder').on('click', (function () {
        this.addEditor();
    }).bind(this));

    $(document).on('keyup', '[data-rel="project-name"]', function () {
        let t = $(this).val();
        if (t.length == 0) {
            t = '<i>Progetto senza titolo</i>';
        }
        $(this).closest('.agency-projects-editor-element').find('.agency-project-accordiontitle').html(t);
    });

    $(document).on('click', '.agency-project-accordiontitle', function () {
        $(this).closest('.agency-projects-editor-element').toggleClass('closed');
    })

    $(document).on('click', '.remover', (function (e) {
        //console.log('remover clicked', $(e.target).closest('.agency-projects-element'));
        this.removeEditor($(e.target).closest('.agency-projects-editor-element'));
    }).bind(this));

    $(document).on('click', '.mediaremover', (function (e) {
        //console.log('mediaremover clicked', $(e.target));
        const id =  $(e.target).attr('data-pid');
        const mid =  $(e.target).attr('data-mid');
        const field = $(document).find('[name="projects['+id+'][medias]['+mid+']"]');
        field.val('');
        $(e.target).parent().remove();
    }).bind(this));
}

cAgencyProjectsEditor.prototype.init = function () {
    this.template = this.container.children('.agency-projects-editor-template').removeClass('agency-projects-editor-template').addClass('agency-projects-editor-element').detach();
}

cAgencyProjectsEditor.prototype.addEditor = function (values) {
    const editor = this.prepareEditor(this.template.clone(), this.container.children().length);
    this.container.append(editor);
    if (values) {
        this.populateValues(editor, values);
    }
    this.finalizeEditor(editor);

    setTimeout((function (editor) {

    }).bind(this), 1000);

    editor.show();
}

cAgencyProjectsEditor.prototype.drawEmptyList = function () {
    this.container.append('<div class="alert alert-warning">Non hai elementi in questa lista, completa il tuo profilo aggiungendone le informazioni che descrivono al meglio l\'azienda.</div>');
}

cAgencyProjectsEditor.prototype.populateValues = function (editor, values) {
    const id = editor.attr('data-identifier');
    console.log('values', values);
    for (let key in values) {
        const el = editor.find('[name="' + this.type + '[' + id + '][' + key + ']"]');
        if (el) {
            el.val(values[key]);
        }

    }

    if (values.medias) {
        for (let mid in values.medias) {
            console.log('cerco il campo ', '[name="' + this.type + '[' + id + '][medias]['+mid+']"]');

            const field = editor.find('[name="' + this.type + '['+id+'][medias]['+mid+']"]');
            field.val(values.medias[mid].name);

            const extension = values.medias[mid].name.split(/[#?]/)[0].split('.').pop().trim();

            if (extension == 'mp4') {
                editor.find('.existingmedia').append('<div class="video"><div class="mediaremover" data-pid="'+id+'" data-mid="'+mid+'">Rimuovi</div><video controls><source src="'+values.medias[mid].name+'" type="video/mp4"></video></div>');
            } else {
                editor.find('.existingmedia').append('<div class="image"><div class="mediaremover" data-pid="'+id+'" data-mid="'+mid+'">Rimuovi</div><img src="'+values.medias[mid].name+'" /></div>');
            }

            field.attr('data-source', 'db');
        }
    }

    if (values.name || values.name == '') {

        let t = values.name;
        if (t.length == 0) {
            t = '<i>Progetto senza titolo</i>';
        }

        editor.find('.agency-project-accordiontitle').html(t);
    }
}

cAgencyProjectsEditor.prototype.removeEditor = function (e) {
    const id = parseInt(e.attr('data-identifier'));
    let nid = id + 1;

    while ($('[data-identifier="' + nid + '"]').length > 0) {
        this.prepareEditor($('[data-identifier="' + nid + '"]'), (nid - 1), nid);
        nid++;
    }

    e.remove();
}

cAgencyProjectsEditor.prototype.prepareEditor = function (editor, id, originalid) {
    editor.attr('data-identifier', id);
    if (!originalid) {
        originalid = 'identifier';
    }

    editor.find('input, textarea, select, checkbox, radio').each(function () {
        const on = $(this).attr('name');

        if (on) {
            const n = on.replace(originalid, id);
            $(this).attr('name', n);
            $(this).attr('id', n);
        }
    });

    return editor;
}

cAgencyProjectsEditor.prototype.getNextMediaId = function (editor) {
    for (let i = 0; i < 5; i++) {
        let ta = editor.find('[data-rel="base64"][data-fid="' + i + '"]');
        if (ta.val().length == 0 ) {
            ta.attr('data-source', 'input'); 
            return i;
        }
    }
}

cAgencyProjectsEditor.prototype.finalizeEditor = function (editor) {
    const id = parseInt(editor.attr('data-identifier'));
    const dzel = editor.find('#dz_identifier');
    dzel.attr('id', 'dz_' + id);

    const dzconfig = {
        url: '/',
        autoProcessQueue: false,
        acceptedFiles: 'image/*,video/mp4',
        maxFiles: 5,
        maxFilesize: 10,
        uploadMultiple: true,
        previewsContainer: editor.find(".preview")[0],
        addRemoveLinks: true,
        init: function () {
            
            this.on("addedfile", function (file) {
                //console.log('dz addedfile', file);
                
                file.qf_mediaid = this.qf_controller.getNextMediaId(this.qf_editor);
                this.qf_editor.find('[data-rel="base64"][data-fid="' + file.qf_mediaid + '"]').val('occupied');

                if (file.type == 'video/mp4') {
                    const reader = new FileReader();
                    reader.onload = (function (event) {
                        this.qf_editor.find('[data-rel="base64"][data-fid="' + file.qf_mediaid + '"]').val(event.target.result);
                    }).bind(this);

                    reader.readAsDataURL(file);
                }
            });

            this.on("thumbnail", function (file) {
                //console.log('dz thumbnail', file);
                this.qf_editor.find('[data-rel="base64"][data-fid="' + file.qf_mediaid + '"]').val(file.dataURL);
            });

            this.on("removedfile", function (file) {
                //console.log('dz removedfile', file);
                this.qf_editor.find('[data-rel="base64"][data-fid="' + file.qf_mediaid + '"]').val('');
            });
        },
    };

    this.media_dz = new Dropzone('#' + dzel.attr('id'), dzconfig);
    this.media_dz.qf_editor = editor;
    this.media_dz.qf_controller = this;
}
window.cAgencyProjectsEditor = cAgencyProjectsEditor;
